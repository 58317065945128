.works__cardlist {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 60px;
  padding: 0;

  width: 90%;
  margin: 0 auto;
  @media screen and (max-width: 1000px) {
    row-gap: 80px;
    column-gap: 24px;
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    row-gap: 60px;
  }
}

.works__card {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  opacity: 0;
  animation: fadeInUp 0.3s ease-out forwards;
  animation-delay: 1.5s;
  &:nth-of-type(2) {
    flex-direction: row-reverse;
    animation-delay: 1.8s;
  }
  &:nth-of-type(3) {
    animation-delay: 2.1s;
  }

}

.works__card-info {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.works__img{
  min-width: 300px;
  min-height: 300px;
  max-height: 300px;
  max-width: 40%;
  width: 100%;
  height: auto;
  @media screen and (max-width: 1000px) {
    display: none;
  }

  img {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;

  }
}


.works__link  {
  width: 160px;
  margin-top: 0;
 
  
  &::after {
    right: -5px;
  }

  &:hover::after {
    right: -17px;
    opacity: 1;
  }
}