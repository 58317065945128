 .home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  max-height: 100vh;
  max-width: calc(100vw - 65px);
  overflow: hidden;
  margin: 0 0 0 65px;

    @media screen and (max-width: 600px) {
      margin: 70px auto 0;
      max-width: 100%;
      max-height: calc(100vh - 70px);
    }
} 

.home__title {
  display: flex;
  justify-content: center;
  flex-direction: row;
  column-gap: 12px;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 58px;
  line-height: 68px;
  text-align: center;
  color: #0d0d0d;
  margin: 80px auto 5px;
  text-align: center;

  span {
    color: #18A3EE;
  }

  @media screen and (max-width: 1000px) {
    font-size: 32px;
    line-height: 40px;
  }

  @media screen and (max-width: 620px) {
   flex-direction: column;
   color: #fff;
  }

  @media screen and (max-width: 370px) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 40px;
   }
}

.home__subtitle {
  margin: 0 auto 45px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #424343;

  font-family: monospace;
  max-width: 80%;

}

.home__buttons {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  margin-bottom: 60px;

  @media screen and (max-width: 650px) {
    flex-direction: column;
    row-gap: 24px;
  }
}

.button {
  padding: 0;
  display: inline-block;
  width: 194px;
  height: 46px;
  font-family: "Rubik";
  font-size: 12px;
  line-height: 46px;
  text-align: center;
  outline: none;
  border: none;
  background-color: transparent;
  text-decoration: none;
  text-transform: uppercase;
  color: #363940;
  border-radius: 4px;
  box-sizing: border-box;
  letter-spacing: 1.5px;

  transition: all 0.3s ease-in-out;

  &.button_contact {
    background-color: #FC8823;
    color: #fff;

    &:hover {
      background-color: #DB7E2B;
      cursor: pointer;
    }
  }

  &.button_services {
    border: 1px solid #18A3EE;
    color: #18A3EE;

    &:hover {
      background-color: #18A3EE;
      color: white;
    }
  }

 &.button_disabled {
  border: none;
  background-color: #e0dfdff1;
  color:#505050;
  

  &:hover {
    pointer-events: none;
    cursor:unset;
  }
 } 
}

.home__img {
  width: 120%;
  height: 120%;
  object-fit: cover;
  object-position: top;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  filter: blur(12px);

}
