.navbar {
  background-color: #363940;
  width: 65px;
  height: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  z-index: 3;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    flex-direction: row;
    width: 100%;
    height: 70px;
    min-height: 70px;
    box-sizing: border-box;
    padding: 0 5%;
    align-items: center;
  }

  &__logo {
    display: block;
    padding: 8px 0 0;

    @media screen and (max-width: 600px) {
      padding: 0;
    }
    
    img {
      display: block;
      margin: 8px auto;
      width: 50px;
      height: auto;
    }
  }


  &__navigation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    row-gap: 20px;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  &__link {
    font-size: 22px;
    color: #CAD1E1;
    display: block;
    line-height: 51px;
    height: 51px;
    position: relative;
    text-decoration: none;
    width: 100%;
    text-align: center;


    @media screen and (max-width: 600px) {
      font-size: 18px;
      width: 44px;
    }

    &_active {
      svg{
        color: #18A3EE;
      }
      
    }

    i {
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      color: #18A3EE;

      svg {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }
    }

    &::after {
      content: attr(dataName);
      font-size: 9px;
      position: absolute;
      bottom: 0;
      display: block;
      width: 100%;
      text-align: center;
      opacity: 0;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out;
      font-family: "Rubik";
    }

  }

  &__links {
    padding: 8px 0 16px;
    margin: 0 auto;
    list-style: none;
    color: #CAD1E1;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    justify-content: flex-end;

    a {
      color: inherit;
      text-decoration: none;
      svg {
        margin-right: 4px;
      }
    }

    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}

.navbar__mobilenav {
  display: none;
  z-index: 1;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #363940;
    color:#CAD1E1;
    position: absolute;
    top: 70px;
    left: -100%;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  .navbar__link {
    max-width: 60%;
    margin: 0 5%;
    width: 100%;
    color: #CAD1E1;
    text-align: start;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;

    &_active {
      color: #18A3EE;

      &:hover {
         
        svg {
          opacity: 1;
        }
      }
    }
  }

  &_open {
    opacity: 1;
    left: 0;
  }
}

.navbar__burger {
  display: none;
  background-color: transparent;
  border: none;
  width: 50px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  row-gap: 6px;
  margin: 0;
  padding: 0;

  .line {
    width: 40px;
    height: 2px;
    background-color: #fff;
    transition: transform 0.3s ease-in-out;
  }

  &.navbar__burger_open{
    .line:nth-of-type(1) {
      transform: rotate(45deg) translate(5px, 0);
    }
    .line:nth-of-type(2) {
      transform: rotate(-45deg)  translate(5px, 0);
    }
  }

  @media screen and (max-width: 600px) {
    display: flex;
  }
}