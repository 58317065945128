.services {
  display: flex;
  flex-direction: column;
}

.services__img {
  width: 100%;
  height: auto;
  max-height: 360px;
  object-fit: cover;
  object-position: bottom;
  @media screen and (max-width: 1000px) {
    max-height: 180px;
    min-height: 180px;
  }
}

.services__cards {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  column-gap: 20px;
  row-gap: 40px;
  max-width: 90%;
  margin: 50px  auto 0;


  @media screen and (max-width: 1239px) {
    grid-template-columns: repeat(3,1fr);
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2,1fr);
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
}

.services__card {
  display: flex;
  flex-direction: column;
  opacity: 0;
  animation: fadeInUp 0.3s ease-out forwards;
  animation-delay: 1.5s;


  
  
  @media screen and (max-width: 550px) {
    min-height: 55px;
  }

  &:nth-of-type(2) {
    animation-delay: 1.8s;
  }

  &:nth-of-type(3) {
    animation-delay: 2.1s;
  }

  &:nth-of-type(4) {
    animation-delay: 2.4s;
  }

}

.services__title {
  min-height: 67px;
  max-width: 90%;

  
  @media screen and (max-width: 1000px) {
    min-height: 0;
    margin-bottom: 12px;
  }
}

