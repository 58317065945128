.vacancy {
  .button {
    margin: 24px 0;
    min-width: 250px;
    opacity: 0;
    animation: fadeInUp 1s 1.2s;
    animation-fill-mode: forwards;

    @media screen and (max-width: 800px) {
      width: 100%;
      min-width: none;
    }
  }
}

.vacancy__info {
  display: flex;
  flex-direction: row;
  column-gap: 40px;
  max-width: 90%;
  margin: 0 auto;
  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
    row-gap: 12px;
  }
}

.vacancy__part {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  min-width: 20%;
}

.vacancy__partLine {
  opacity: 0;
  animation: fadeInUp 1s 2.1s;
  animation-fill-mode: forwards;
  &:nth-of-type(2) {
    animation-delay: 2.4s;
  }

  &:nth-of-type(3) {
    animation-delay: 2.7s;
  }

  &:nth-of-type(4) {
    animation-delay: 3s;
  }
}

.vacancy__description {
  opacity: 0;
  animation: fadeInUp 1s 1.5s;
  animation-fill-mode: forwards;
}

.vacancy__block-title {
  margin-bottom: 18px;
}

.vacancy__list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 0 0 0 20px;
  margin: 0;
}

.vacancy__blocks {
  max-width: 90%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  row-gap: 34px;

  @media screen and (max-width: 800px) {
    row-gap: 24px;
    margin-top: 24px;
  }
}

.vacancy__block {
  width: 100%;

  opacity: 0;
  animation: fadeInUp 1s 3.3s;
  animation-fill-mode: forwards;

  &:nth-of-type(2) {
    animation-delay: 3.7s;
  }

  &:nth-of-type(3) {
    animation-delay: 4s;
  }

  &:nth-of-type(4) {
    animation-delay: 4.3s;
  }
}