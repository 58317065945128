.notfound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  background:rgb(255, 255, 255);
	text-align: center;
	position: relative;

  max-height: 100vh;
  max-width: calc(100vw - 65px);
  overflow: hidden;
  margin: 0 0 0 65px;

    @media screen and (max-width: 600px) {
      margin: 70px auto 0;
      max-width: 100%;
      max-height: calc(100vh - 70px);
    }

    &:after {
      content:'';
      display:inline-block;
      width:100%;
      height:100px;
      background:#18A3EE;
      position:absolute;
      top:-84px;
      left:0;
      transform:skewY(-4deg);
      -webkit-transform:skewY(-4deg);
    }

    &:before {
      content:'';
      display:inline-block;
      width:100%;
      height:100px;
      background:#18A3EE;
      position:absolute;
      bottom:-84px;
      left:0;
      transform:skewY(-4deg);
      -webkit-transform:skewY(-4deg);
    }

    .button {
      z-index: 88;
      margin-top: 40px;
    }
}


p {
	font-size: 240px;
	color: #fff;
	position: relative;
  z-index: 9;
  margin: 0;
}

small { 
	color: #fff;

	position: relative;
  z-index: 9;
  font-size: 40px; 
  margin: 0;
}

 .circle.small {
	width: 250px;
	height: 250px;
	border-radius: 50%;
	background: #18A3EE;
	position: absolute;
	z-index: 1;
	top: 80px;
	left: 50%;
	animation: 7s smallmove infinite cubic-bezier(1,.22,.71,.98);	
	-webkit-animation: 7s smallmove infinite cubic-bezier(1,.22,.71,.98);
	animation-delay: 1.2s;
	-webkit-animation-delay: 1.2s;
}

.circle.med {
	width: 400px;
	height: 400px;
	border-radius: 50%;
	background: #18A3EE;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 10%;
	animation: 7s medmove infinite cubic-bezier(.32,.04,.15,.75);	
	-webkit-animation: 7s medmove infinite cubic-bezier(.32,.04,.15,.75);
	animation-delay: 0.4s;
	-webkit-animation-delay: 0.4s;
}

.circle.big {
	width: 600px;
	height: 600px;
	border-radius: 50%;
	background: #18A3EE;
	position: absolute;
	z-index: 1;
	top: 200px;
	right: 0;
	animation: 8s bigmove infinite;	
	-webkit-animation: 8s bigmove infinite;
	animation-delay: 3s;
	-webkit-animation-delay: 1s;
}

@keyframes smallmove {
	0% { top: 0%; left: 45%; opacity: 0.9; }
	25% { top: 80%; left: 40%; opacity:0.6; }
	50% { top: 60%; left: 55%; opacity:0.4; }
	75% { top: 20%; left: 40%;  opacity:0.6; }
	100% { top: 10%; left: 45%; opacity: 1; }
}

@keyframes medmove {
	0% { top: 0px; left: 20%; opacity: 0.8; }
	25% { top: 95%; left: 80%; opacity:0.7; }
	50% { top: 80%; left: 55%; opacity:0.4; }
	75% { top: 25%; left: 40%;  opacity:0.6; }
	100% { top: 0px; left: 20%; opacity: 0.9; }
}

@keyframes bigmove {
	0% { top: 0px; right: 4%; opacity: 0.5; }
	25% { top: 25%; right: 40%; opacity:0.4; }
	50% { top: 60%; right: 45%; opacity:0.8; }
	75% { top: 30%; right: 35%;  opacity:0.6; }
	100% { top: 0; right: 4%; opacity: 0.5; }
}
