.page {
  width: 100%;
  height: auto;
  display: block;
  overflow: hidden;
}

.container {
  will-change: contents;
  max-width: calc(100% - 65px);
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 30px;
  margin: 0 0 0 65px;
  height: auto;
  position: relative;
  opacity: 0;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;

 

  @media screen and (max-width: 600px) {
    margin: 90px auto 30px ;
  }
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 68px;
  text-transform: uppercase;
  color: #CAD1E1;
  margin: 30px auto 30px;
  width: 90%;
  

  span {
    color: #18A3EE;
  }

  @media screen and (max-width: 1000px ) {
    font-size: 32px;
    line-height: 40px;
    
  }
}