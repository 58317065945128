.career {
  display: flex;
  flex-direction: column;
  &:before {
    content:'';
    display:inline-block;
    width:100%;
    height:100px;
    background:#18A3EE;
    position:absolute;
    bottom:-84px;
    left:0;
    transform:skewY(-4deg);
    -webkit-transform:skewY(-4deg);
  }
}

.career__cardlist {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 40px;
  padding: 0;

  width: 90%;
  margin: 0 auto;
  @media screen and (max-width: 1000px) {
    row-gap: 60px;
  }
}

.career__card {
  max-width: 70%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  row-gap: 12px;

  opacity: 0;
  animation: fadeInUp 0.3s ease-out forwards;
  animation-delay: 1.5s;
  &:nth-of-type(2) {
    animation-delay: 1.8s;
  }
  &:nth-of-type(3) {
    animation-delay: 2.1s;
  }

  @media screen and (max-width: 1000px) {
    max-width: 100%;
  }

}


.career__link {
  width: 160px;

  &::after {
    right: -5px;
  }

  &:hover::after {
    right: -17px;
    opacity: 1;
  }
}

.career__show {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  
  margin-top: 100px;
  margin-left: 5%;
  opacity: 0;
  animation: fadeInUp 0.3s ease-out 5s forwards;
  .button {
    margin-top: 16px;
  }
}