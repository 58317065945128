.about {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 5% 60px;

  .title {
    margin-right: unset;
    margin-left: unset;
    width: 100%;
  }
}


.about__info {
  display: flex;
  flex-direction: row;
  column-gap: 16px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    row-gap: 24px;
  }
}




.about__sertifitates {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (max-width: 1000px) {
    flex-direction: row;
    column-gap: 24px;
  }


  .about__img {
    max-width: 60px;
    max-height: 100%;
  }

}


.statistic {
  display: flex;
  flex-direction: row-reverse;
  column-gap: 16px;
  width: 100%;
  margin: 30px 0 0;

  @media screen and (max-width: 1000px) {
    margin: 40px 0 0 ;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}


.statistic__img {
  width: 100%;
  max-width: calc(50% - 10px);
  object-fit: cover;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    margin: 0 0 40px;

  }
}

.statistic__info {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.statistic__card {
  opacity: 0;
  animation: fadeInUp 0.3s ease-out forwards;
  animation-delay: 1.5s;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  &:nth-of-type(2) {
    animation-delay: 1.8s;
  }

  &:nth-of-type(3) {
    animation-delay: 2.1s;
  }
}
